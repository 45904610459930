import React, { useEffect, useRef } from "react";

const Kv = () => {

    const layerRefs = useRef([]);

    return (
        <div className="kv">
            <div
                data-speed="0.05"
                ref={(el) => (layerRefs.current[0] = el)}
            ><img src="/images/kv_flight.png" alt="" /></div>
            <div
                data-speed="0"
                ref={(el) => (layerRefs.current[1] = el)}
            ><img src="/images/kv_phone.png" alt="" /></div>
            <div
                data-speed="-0.025"
                ref={(el) => (layerRefs.current[2] = el)}
                className="prize-1"
            ><img src="/images/kv_prize_1.png" alt="" /></div>
            <div
                data-speed="-0.025"
                ref={(el) => (layerRefs.current[3] = el)}
                className="prize-2"
            ><img src="/images/kv_prize_2.png" alt="" /></div>
            <div
                data-speed="0"
                ref={(el) => (layerRefs.current[1] = el)}
            ><img src="/images/kv_photos.png" alt="" /></div>
            <div
                data-speed="-0.2"
                ref={(el) => (layerRefs.current[4] = el)}
                className="coins-1"
            ><img style={{transform: 'translateY(-4%)'}} src="/images/kv_badge.png" alt="" /></div>
            {/* <div
                data-speed="-0.2"
                ref={(el) => (layerRefs.current[4] = el)}
                className="coins-2"
            ><img src="/images/kv_coins_2.png" alt="" /></div> */}
        </div>
    );
};

export default Kv;
