const EN = () => {
    return (
        <>
            <h1>“My HKG Upgrade Your Journey, Experience Your Way” Promotion Campaign (the “Campaign”)</h1>
            <h2>Terms and Conditions</h2>
            “My HKG Upgrade Your Journey, Experience Your Way” Promotion Campaign (the “Campaign”)
            Terms and Conditions

            How to participate in My HKG Upgrade Your Journey, Experience Your Way Campaign Lucky Draw (the “Lucky Draw”):
             
            By participating in the Lucky Draw, each participant confirms that he/she has read, understood and agreed to be bound by these terms and conditions set out herein, including any other requirements set out in any related promotional material, and all amendments, additions, replacements and modifications that may be made from time to time.
            The participation period starts from 10 September 2024 and last until 13 October 2024 (both dates inclusive).
            If there is any conflict between the information contained in the promotional materials and these terms and conditions, these terms and conditions shall apply and prevail. In the event of discrepancies in the terms and conditions between the English and Chinese versions, the English version shall prevail.

            In case of any dispute in respect of these terms and conditions or any other matters arising out of the Lucky Draw, the Authority reserves the right of final decision. All decisions made by the Authority regarding the interpretation and application of these terms and conditions or on all matters relating to the Lucky Draw shall be final and conclusive in each case.

             	
            Eligibility and method of participation:
             
            The participant must be 18 years of age or above when he/she enters the Lucky Draw.
            To be eligible for the Lucky Draw, participants must complete all three steps as stated below (“Eligible Participant(s)”):
            Step 1: Use the “My HKG” app to complete a travel personality test. 

            Step 2: Share their travel personality test result either with a public post on their own Facebook account or a public story on their own Instagram account.

            Step 3: Tag two friends and add the hashtag #mytravelpersonality in the Facebook post or Instagram story. For Facebook users, they are required to tag our official “Hong Kong International Airport 香港國際機場” Facebook page. For Instagram users, they are required to tag our official Instagram account @hongkongairport.

            When posting on their own social media accounts, participants agree not to post any content that is libellous, harassing, abusive, obscene, vulgar, sexually explicit, or inappropriate concerning race, gender, sexuality, ethnicity, or other intrinsic characteristics. Additionally, any content containing false or misleading statements, as well as content that speaks detrimentally of Hong Kong International Airport without proper, fair or reasonable basis or that may involve or potentially involve unlawful acts, is strictly prohibited. The Airport Authority (the “Authority”) reserves the right to screen out any content that violate the aforementioned requirements.
            Employees of the Authority, and advertising agencies are not eligible and are not permitted to participate in the Lucky Draw.
            Each participant of the Lucky Draw undertakes, represents and warrants to the Authority that he/she has only used one Facebook account/ Instagram account to participate in the Lucky Draw. Any participant who uses more than one Facebook account/ Instagram account to participate in the Lucky Draw will be disqualified from the Lucky Draw.
            Only the winners will be notified individually via inbox message after the winners are announced. The winners will be asked to provide their names and email addresses for prize redemption arrangement. The “Personal Information Collection Statement and Privacy Policy” set out below applies. If a winner fails to respond within 48 hours after being notified, or if any incorrect or incomplete personal information is provided, it will be considered as surrendering the prize, and the prize will not be reissued to the winner.
            If a participant has already won a prize, he or she will not be eligible to participate in the remaining Lucky Draw pool.


            Disqualification
            If you encounter any of the following situations, the Authority reserves the sole and absolute right to cancel or exclude your participation in this Lucky Draw and/or disqualify you from winning any prizes, without prior notice:i. Violation of these terms and conditions; ii. Provision of incorrect or incomplete personal information (if applicable); iii. Provision of personal information during prize collection that does not match the information provided during participation (if applicable);iv. Participation in this Lucky Draw through illegal or dishonest means; or vi. The Authority deems it necessary to cancel or exclude your eligibility under reasonable circumstances.

            Prizes and prize collection procedures
            Five travel styles will be assessed in “My HKG” app’s travel personality test. Each Participant who completes the test will get a result suggesting which one of these five travel styles aligns most closely with the Participant’s personal travel preferences. Under each of the five travel styles, 100 winners will be drawn randomly by a computer from amongst the Participants that possess that particular travel style.

            There are 500 prizes (“the Prizes”) in total. Details of the prizes are as follows:




            The prize redemption will be conducted in an electronic format, in the form of QR codes or digital vouchers. The QR codes and/or digital vouchers of the Prizes for each winner are unique and the winners are solely responsible for keeping them safe and secure. If any QR code and/or digital voucher is found to have been used when it is presented for using the offers, the Authority will not provide a new QR code and/or digital voucher as replacement. 

            The Lucky Draw will be conducted by 17 October 2024 by a computer system to randomly select 100 winners under each of the five travel styles. Winners will be notified individually via Facebook or Instagram inbox messages.
            The winner’s list will be announced on our official “Hong Kong International Airport 香港國際機場” Facebook page and Instagram account @hongkongairport by 22 October 2024. The Authority will contact the winners through Facebook/Instagram inbox messages for prize redemption arrangement after the winners’ list is announced. The list of all the winners will be published in Sing Tao Daily and The Standard within 10 days after the completion of the Lucky Draw process.
            The winners will be notified of the prize redemption details via email within 10 days after being notified of winning the prize. The winner must claim the prize within 30 days after receiving the prize redemption email, otherwise the prize will be considered forfeited.

            If this Lucky Draw is not capable of running as planned due to the circumstances of fire, flooding, or theft that affect the administration, security, fairness, integrity, or proper conduct of Lucky Draw, the Authority reserves the right to cancel the Lucky Draw without prior notice.

            The Prizes cannot be exchanged for cash or other gifts. The Prize cannot be transferred, replaced or returned.
            Prizes are subject to the product or service terms and conditions of the relevant prize suppliers and the relevant prize redemption period. Please contact the prize suppliers for details.

            This Lucky Draw is not affiliated with Facebook and Instagram and is neither supported nor managed by Facebook and Instagram. 
            In case of any dispute, the Authority reserves the right of final decision.

            Personal Information Collection Statement and Privacy Policy 

            Winners will be asked to provide the following information:
            The winner’s full name; and
            Email address
            Mobile number 

            The winners represent and warrant that the personal data which he/she provides to the Authority during the Lucky Draw is true, complete and accurate. The Authority shall not be held liable and shall be fully indemnified by the winner(s) for any incorrect and inaccurate personal data provided.

            Personal data collected from the winner will be used by the Authority for the following purposes:
            Checking the Participant’s eligibility to participate or to win in the Lucky Draw;
            Administering the Lucky Draw;
            Facilitating future audit of the Lucky Draw;
            Notifying the prize winners and the relevant prize redemption arrangement; 
            Transferring to the prize suppliers for the winners identification and;
            Any other purposes incidental to the purposes stated above.

            The Authority shall keep the winners’ personal data confidential at all times, and only collect, use and store it in accordance with this Personal Information Collection Statement.

            Personal data of the winner will be kept confidential and will not be disclosed to any third party except for any person or company (including third party agent or sub-contractor) (“Third Party Service Providers” who are acting for or is engaged by the Authority in implementing the Lucky Draw, including communicating with the winners in relation to the Lucky Draw, facilitating the selection and notification of prize winners and the relevant prize redemption arrangement; or any other third party where such disclosure is required by law or court of law, or where such disclosure is necessary to protect the Authority’s interests as permitted by law.

            The Third Party Service Providers are under a duty of confidentiality to the Authority and are only permitted to use the winners’ personal data in connection with the purposes specified as set out in Paragraph 3 above.

            The Authority will take all reasonable measures to ensure the personal data collected will be kept secure against unauthorised or accidental access, loss or processing. 

            Personal data provided to the Authority will be retained by the Authority for 3 months, after which it will be destroyed. Notwithstanding this, if any personal data is required as specified under applicable laws or in connection with any legal proceedings to be kept for a period of time longer than expressly provided in this Personal Information Collection Statement and Privacy Policy, the same will be so retained but the Authority will take practical steps to ensure that such data will not be kept longer than is necessary for the fulfilment of such purposes. 

            The winner has the right to access and verify his/her personal data that the Authority holds. A data access request form can be downloaded from the website of the Office of the Privacy Commissioner for Personal Data, Hong Kong. 

            The winner may also request the Authority to correct his/her personal data that the Participant has accessed if such data is inaccurate. Please note that under the Personal Data (Privacy) Ordinance, the Authority has a right to charge a reasonable fee for complying with a data access request. Data access and correction requests should be addressed to our General Personal Data Officer, to the address stated below. 

            Participants are advised to refer to the Authority’s Privacy Policy at www.hongkongairport.com/en/privacy-policy.page for more details. Should Participants have any enquiries, please send an email to MYHKGPROMO@hkairport.com.

            If the winner has other questions or wishes to contact the Authority regarding this Personal Information Collection Statement, please contact our General Personal Data Officer via the address below: 




            Competition license number for business promotion: xxxxxx
            Trade Promotion Competition Licence No.: XXXXX,. 



        </>
    )
}
const TC = () => {
    return (
        <>
            <h1>TC “My HKG Upgrade Your Journey, Experience Your Way” Promotion Campaign (the “Campaign”)</h1>
            <h2>Terms and Conditions</h2>
        </>
    )
}
const SC = () => {
    return (
        <>
            <h1>SC “My HKG Upgrade Your Journey, Experience Your Way” Promotion Campaign (the “Campaign”)</h1>
            <h2>Terms and Conditions</h2>
        </>
    )
}


export { EN, TC, SC };