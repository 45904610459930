import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import "./styles/common.scss";
import { useTranslation } from "react-i18next";
import { QuizProvider } from "./context/QuizContext.js";
import { AppProvider } from "./context/AppContext.js";
import Home from "./pages/Home.js";
import Quiz from "./pages/Quiz.js";
import Result from "./pages/Result.js";
import Test from "./pages/Test.js";
import { DisplayState, LoadingScreen } from "./components/Misc.js";

function App() {
    const { t, i18n } = useTranslation();
    const [currentPage, setCurrentPage] = useState("home");

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const lang = queryParams.get('lang');

        if (lang) {
            i18n.changeLanguage(lang);
        }
    },[]);

    useEffect(() => {
        setRemSize(i18n.language);
    }, [i18n.language]);

    const setRemSize = (language) => {
        const root = document.documentElement;
        if (language === 'en') {
          root.style.setProperty('--rem-size', '12px');
          root.style.setProperty('font-size', '12px');
        } else {
          root.style.setProperty('--rem-size', '16px');
          root.style.setProperty('font-size', '16px');
        }
    };

    const changePage = (page) => {
        setCurrentPage(page);
    };

    const renderPage = () => {
        switch (currentPage) {
            case "home":
                return <Home onChangePage={changePage} />;
            case "quiz":
                return <Quiz onChangePage={changePage} />;
            case "result":
                return <Result onChangePage={changePage} />;
            default:
                return <Home onChangePage={changePage} />;
        }
    };

    return (
        <AppProvider>
            <div className="App">
                <div className="app-wrapper">
                    <QuizProvider>
                        <Router>
                            <Routes>
                                <Route path="/result" element={<Result />} />
                                <Route path="/test" element={<Test />} />
                                <Route path="*" element={renderPage()} /> 
                            </Routes>
                        </Router>
                        {/* {renderPage()} */}

                        {/* <DisplayState /> */}
                        <LoadingScreen />
                    </QuizProvider>
                </div>
            </div>
        </AppProvider>
    );
}

export default App;
