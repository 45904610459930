// src/components/Quiz.js
import React, { useState, useContext, useEffect } from 'react';
import { QuizContext } from '../context/QuizContext';
import Question from '../components/Question';
import Progress from '../components/Progress';
import { useTranslation, Trans } from 'react-i18next';
import { AppContext } from "../context/AppContext.js";
import { useNavigate } from "react-router-dom";

const Quiz = ({onChangePage}) => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(QuizContext);
  const { dispatch: appDispatch } = useContext(AppContext);
  const { t, i18n } = useTranslation();
  const [finished, setFinished] = useState(false);

  const handleAnswer = (scores) => { 
    dispatch({
      type: 'ANSWER_QUESTION',
      payload: {
        scores
      },
    });

    if (state.currentQuestion === 9) {
      appDispatch({ type: 'SET_LOADING', payload: true });
      setTimeout(() => {
        appDispatch({ type: 'SET_LOADING', payload: false });
        setFinished(true);
        // onChangePage('result');
      },1000);
    }
  };

  useEffect(() => {
    if(finished){
      navigate(`/result?lang=${i18n.language}&type=${state.resultId}`);
    }
  }, [finished]);

  const currentQuestionKey = `questions.${state.currentQuestion}.question`;
  const currentOptionsKey = `questions.${state.currentQuestion}.options`;

  return (
    <div className="page page-questions" style={{
        backgroundImage: 'url("/images/bg-question.jpg")'
    }}>
      <div className="relative z-[2]">
        <Progress currentQuestion={state.currentQuestion} />
        <Question
          question={t(currentQuestionKey)}
          options={t(currentOptionsKey, { returnObjects: true })}
          onAnswer={handleAnswer}
        />
      </div>
    </div>
  );
};

export default Quiz;
