// src/context/QuizContext.js
import React, { createContext, useReducer } from 'react';

const initialState = {
  isLoading: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.payload
      };
    default:
      return state;
  }
};

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const triggerGTMEvent = (eventName, eventParams = {}) => {
    if (window.gtag) {
        window.gtag('event', eventName, eventParams);
    } else {
        window.dataLayer = window.dataLayer || [];
        const event = {
          event: eventName,
          event_name: eventName,
          ...eventParams
        };
        // console.warn('gtag is not defined. Use dataLayer.push instead.');
        // console.log('event', event);
        window.dataLayer.push(event);
    }
  };

  return (
    <AppContext.Provider value={{ state, dispatch, triggerGTMEvent }}>
      {children}
    </AppContext.Provider>
  );
};
